import type { IncomingMessage } from 'node:http';
import { fetchAPI } from '../fetch-api';
import type { FetchOptions } from '../fetch-api';
import { API_USER } from '../endpoints';
import type { UserResponse } from '../types/users';
import { isLoggedIn } from './is-logged-in';

export async function authenticate(
  req: IncomingMessage,
): Promise<UserResponse> {
  return getUser(req);
}

export async function getUser(
  req?: IncomingMessage | null,
  opts?: FetchOptions,
): Promise<UserResponse> {
  if (!isLoggedIn(req)) return {};

  return fetchAPI(`${API_USER}?flags=true`, {
    ...opts,
    req: req || undefined,
    throwOnHTTPError: opts?.throwOnHTTPError ?? true,
  });
}
